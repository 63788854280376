import TruckIcon from '@/components/icons/TruckIcon.vue'
import TrailerIcon from '@/components/icons/TrailerIcon.vue'
import TransporterIcon from '@/components/icons/TransporterIcon.vue'
interface Category {
  position: number
  slug: string
  icon: typeof TruckIcon | typeof TrailerIcon | typeof TransporterIcon
  img: string
  path: string
  title: string
}

export const useSortedCategories = (): Category[] => {
  const config = useRuntimeConfig().public

  return [
    {
      position: config.CATEGORY_ORDER.TRAILER,
      slug: 'trailer',
      icon: TrailerIcon,
      img: 'trailer_image.png',
      path: '/auflieger',
      title: 'Auflieger',
    },
    {
      position: config.CATEGORY_ORDER.TRUCK,
      slug: 'truck',
      icon: TruckIcon,
      img: 'truck_image.png',
      path: '/sattelzugmaschinen',
      title: 'Sattelzugmaschinen',
    },
    {
      position: config.CATEGORY_ORDER.LKW,
      slug: 'lkw',
      icon: TransporterIcon,
      img: 'lkw_image.png',
      path: '/lkw',
      title: 'LKW',
    },
  ].sort((x, y) => x.position - y.position)
}

export const useCategoryNavigationObject = (slug: string) => {
  const category = useSortedCategories().find(
    (category) => category.slug === slug
  )

  if (!category) {
    return { title: '', path: '' }
  }

  return { title: category.title, path: category.path }
}
